import React from "react";
import { Link } from "react-router-dom";
import {NavDropdown,Nav ,Navbar, Container} from "react-bootstrap";

function Header() {
  return (
    <div style={{marginTop:"20px"}}>


  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Container>
  <Navbar.Brand href="/">Erik Dziekonski's Portfolio</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
      <Nav.Link href="/aboutme">About Me</Nav.Link>
      <Nav.Link href="/contact">Contact</Nav.Link>
      <Nav.Link href="/experiments">Experiments</Nav.Link>
    </Nav>
    <Nav>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>


    </div>
  );
}

export default Header;
