import React from "react"
import {Link, Redirect} from "react-router-dom"
import axios from "axios"
import Header from "../header"
import Footer from "../footer"

class SingleProduct extends React.Component {
    constructor(){
        
        super()
        this.state = {
            redirect: false,
            termsOfUseCheckbox: true,
            loading: true
        }
        this.handleChange = this.handleChange.bind(this)

    }

    componentDidMount() {
        if (this.props.match.params){
            var self = this
            axios.get(process.env.REACT_APP_API_DOMAIN + '/get_project/' + this.props.match.params.name)
            .then((resp) => {
                this.setState({singleExp:resp.data, loading:false})
              })
            .catch(error => {
                this.setState({redirect:true})
              })
        }else{
            this.setState({redirect:true})
        }
    }

    handleChange(event){
        const {name,value,type,checked} = event.target
        type === "checkbox" ? this.setState({[name]:checked}) : this.setState({[name]: value})
    }

    
    render(){

        if (!this.state.loading){
        return(

          <div>
            {(this.state.redirect) ? <Redirect to="/404"/> : null}

          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
          <Header/>

          <div>
            <div className="container d-xl-flex flex-column align-items-xl-center" style={{marginTop: 'auto', marginRight: 'auto', marginLeft: 'auto'}}>
                <h1 className="bigHeader">{this.state.singleExp.name}</h1>
                <p>{this.state.singleExp.date}</p><img src={process.env.REACT_APP_API_DOMAIN + "/static/img/" + this.state.singleExp.image} style={{width: '80%'}} /></div>
            <div className="container">
                <div>
                <h1 className="boxHeading">State</h1>
                <p>{this.state.singleExp.state}</p>
                </div>
                <div>
                <h1 className="boxHeading">Description</h1>
                <div dangerouslySetInnerHTML={{__html: this.state.singleExp.description}}/> 
                {/* {this.state.singleExp.description} */}
                </div>
                <div>
                {(this.state.singleExp.abandoned) ? <h1 className="boxHeading">Why was it abandoned?</h1>: null}
                <p>{this.state.singleExp.abandoned}</p>
                </div>
            </div>
            </div>
            <Footer/>
    </div>        
        )
    }else{
        return(
            <div>
            {(this.state.redirect) ? <Redirect to="/404"/> : null}
            </div>
        )
    }
}


} 

export default SingleProduct