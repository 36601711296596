import React from "react"
import {Redirect} from "react-router-dom"
import axios from "axios"

import Header from "../header"
import Cookies from 'universal-cookie';


class Login extends React.Component {
    constructor(){
        
        super()
        this.state = {
            redirect: false,
            termsOfUseCheckbox: true,
            failedlogin: false,
            redirect: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

    }


    handleChange(event){
        const {name,value,type,checked} = event.target
        type === "checkbox" ? this.setState({[name]:checked}) : this.setState({[name]: value})
    }

    handleSubmit(){
        var self = this;

        axios.post(process.env.REACT_APP_API_DOMAIN + "/login", {email: this.state.email, password: this.state.password})
        .then((resp) => {
            if (resp.status === 202) {
              const cookies = new Cookies();
              cookies.set('jwttoken', resp.data, { path: '/' });
              self.setState({redirect: true})
            }
          })
        .catch(error => {
            this.setState({failedlogin: true})
        })
    }


    render(){


        return(
          <div>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
            <title>Login</title>
            <Header/>

            {(this.state.redirect) ? <Redirect to="/dashboard"/> : null}

            <div className="shadow dzform" style={{backgroundColor: 'rgba(71,93,98,0)', color: '#8800f2'}}>
                <h2 className="sr-only">Login Form</h2>
                <div className="illustration"><i className="icon ion-ios-locked-outline" style={{color: '#8800f2'}} /></div>
                <div className="form-group"><input className="form-control"  value={this.state.email} autocomplete="on" onChange={this.handleChange} type="email" name="email" placeholder="Email" /></div>
                <div className="form-group"><input className="form-control" type="password" name="password" placeholder="Password" value={this.state.password} autocomplete="off" onChange={this.handleChange}/></div>
                <div className="form-group"><button className="btn btn-primary btn-block" type="submit" style={{backgroundColor: '#8800f2'}} onClick={this.handleSubmit} >Log In</button></div>
                {(this.state.failedlogin) ? <p style={{color:"red !important"}}>Failed login</p> : null}
            </div>


        </div>

        
        )
    
}


} 

export default Login