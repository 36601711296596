import axios from "axios";
import React from "react";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";

import Header from "../header";
import Footer from "../footer"


class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      nameToRemove: "",
      fillAllDelete: false,
      uploadMessage: "",
      deleteMessage: "",
      
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddExp = this.handleAddExp.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleDelExp = this.handleDelExp.bind(this);



  }

  componentDidMount() {
    const cookies = new Cookies();
   
    var jwttoken = cookies.get("jwttoken");
    if (jwttoken){
      this.setState({jwttoken: jwttoken})
    }else{
      this.setState({ redirect: true });

    }
    

  }

  handleChange(event) {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? this.setState({ [name]: checked })
      : this.setState({ [name]: value });
  }

  onFileChange(event){
    this.setState({ image: event.target.files[0] });
  }


  handleAddExp() {

    var formIsValid = true;

    if(!this.state.name){
      formIsValid = false;
   }else if(!this.state.image){
    formIsValid = false;
   }else if(!this.state.state){
    formIsValid = false;
   }else if(!this.state.date){
    formIsValid = false;
   }else if(!this.state.description){
    formIsValid = false;
   }else if(!this.state.shortdesc){
    formIsValid = false;
   }
   var filename = ""
   var self = this
   //Devolver algo q diga bn o mal
   if (formIsValid){

    this.setState({missingStuff:false})
    const formData = new FormData();
    formData.append('file', this.state.image);
    axios.post(process.env.REACT_APP_API_DOMAIN + "/uploadimage", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((resp) => {
      console.log('File uploaded');
      filename = resp.data

      //Una vez uploadeada la imagen, coge el nombre y lo uplodea junto a lo demas
      axios({
        method: "post",
        url: process.env.REACT_APP_API_DOMAIN + "/add_project",
        data: {name:this.state.name,
          state:this.state.state,
          date: this.state.date,
          description: this.state.description,
          abandoned: this.state.abandoned,
          shortdesc: this.state.shortdesc,
          image: filename
        },
        headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.state.jwttoken}` },
      })
        .then(function (response) {
          console.log(filename)
          console.log("succesfully uploaded");
          self.setState({uploadMessage:"success"})
        })
        .catch(function (response) {
          console.log(response);
          self.setState({uploadMessage:"failed"})

        });
        setTimeout(() => this.setState({uploadMessage:""}), 3000);



    }).catch((resp) => {
      console.log(resp.data)
    });
        
  }else{
    this.setState({uploadMessage:"missing"})
    setTimeout(() => this.setState({uploadMessage:""}), 3000);
  }

  }

  handleDelExp() {

    var self = this
    if (this.state.nameToRemove.length < 1){
      this.setState({deleteMessage:"missing"})
    }else{
      this.setState({fillAllDelete:false})
      axios({
        method: "post",
        url: process.env.REACT_APP_API_DOMAIN + "/remove_project",
        data: {
          name: this.state.nameToRemove
        },
        headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.state.jwttoken}` },
      })
        .then(function (response) {
          console.log("succesfully deleted");
          self.setState({deleteMessage:"success"})
        })
        .catch(function (response) {

          self.setState({deleteMessage:"failed"})
        });
    }
    setTimeout(() => this.setState({uploadMessage:""}), 3000);

}

  render() {
    return (
      <div>
        {this.state.redirect ? <Redirect to="/login" /> : null}
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
        />
        <Header />

        <div>
          <div
            className="contact-clean"
            style={{ backgroundColor: "rgba(255,255,255,0)" }}
          >
            <div
              className="shadow dzform"
              method="post"
              style={{ backgroundColor: "#141D2B" }}
            >
              <h2 className="text-center" style={{ color: "rgb(255,255,255)" }}>
                Add a new experiment
              </h2>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Name of experiment"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </div>

              <div className="form-group">
                <input
                  type="file"
                  name="image"
                  onChange={this.onFileChange}                />
              </div>
              
              <div className="form-group">
                <input
                  className="form-control"
                  type="date"
                  name="date"
                  placeholder="Date"
                  onChange={this.handleChange}
                  value={this.state.date}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="state"
                  placeholder="State"
                  onChange={this.handleChange}
                  value={this.state.state}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="abandoned"
                  placeholder="Why was it abandoned"
                  onChange={this.handleChange}
                  value={this.state.abandoned}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="shortdesc"
                  placeholder="Short Description"
                  onChange={this.handleChange}
                  value={this.state.shortdesc}
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="description"
                  placeholder="Description (HTML MARKUP OBLIGATORY)"
                  rows={14}
                  defaultValue={""}
                  onChange={this.handleChange}
                  value={this.state.description}
                />
              </div>
              
              <div className="form-group">
                <button className="btn DzButton" onClick={this.handleAddExp}>
                  send
                </button>
                
                {
                (this.state.uploadMessage == "success") ? <span style={{color:"green", marginLeft: "10px"}}>Succesfully uploaded</span> :
                (this.state.uploadMessage == "failed") ? <span style={{color:"red", marginLeft: "10px"}}>Error</span> :
                (this.state.uploadMessage == "missing") ? <span style={{color:"red", marginLeft: "10px"}}>Please fill out every field</span> : 
                null

                /*No hacer esto, en vez hacer algun json q sea error:mensaje o success:mensaje,
                el error/succes define el color y lgo el mensaje es el q se enseña*/
                 }


              </div>
            </div>
            <div
              className="shadow dzform"
              style={{ backgroundColor: "#141D2B", marginTop: 37 }}
            >
              <h2 className="text-center" style={{ color: "rgb(255,255,255)" }}>
                Remove an experiment
              </h2>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  name="nameToRemove"
                  placeholder="Name"
                  onChange={this.handleChange}
                  value={this.state.nameToRemove}/>
              </div>
              <div className="form-group">
                <button className="btn DzButton" type="submit" onClick={this.handleDelExp}>
                  Remove&nbsp;
                </button>

                {
                (this.state.deleteMessage == "success") ? <span style={{color:"green", marginLeft: "10px"}}>Succesfully deleted</span> :
                (this.state.deleteMessage == "failed") ? <span style={{color:"red", marginLeft: "10px"}}>Error</span> :
                (this.state.deleteMessage == "missing") ? <span style={{color:"red", marginLeft: "10px"}}>Please fill out every field</span> : 
                null
                 }

              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Dashboard;
