import React from "react";
import {Link} from "react-router-dom"

function Innerbox(props) {
  return (
    <Link to={"/view-experiment/" + props.id}>
    <div className="shadow innerbox">
      <div className="d-xl-flex justify-content-xl-center">
        <img
          src={process.env.REACT_APP_API_DOMAIN + "/static/img/" + props.img}
          style={{
            width: "266px" /*marginLeft: 'auto', */ /*marginRight: 'auto'*/,
            height: "150px"
          }}
        />
      </div>
      <h1 className="boxHeading">{props.id}</h1>
      <p className="boxDate">{props.date}</p>
      <p>
        {props.description.slice(0,400)}
      </p>
    </div>
    </Link>
  );
}

export default Innerbox;
