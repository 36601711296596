import HomePage from "./components/homePage/homePageMain"
import AboutMe from "./components/aboutMe/aboutMe"
import Login from "./components/login/login"
import Contact from "./components/contact/contact"
import Dashboard from "./components/dashboard/dashboard"
import SingleProduct from "./components/singleProduct/singleProduct"
import AllProjects from "./components/allProjects/allProjects"

import "./bootstrap/css/bootstrap.min.css"
import "./css/Contact-Form-Clean.css"
import "./css/Login-Form-Dark.css"
import "./css/Navigation-Clean.css"
import "./css/styles.css"
import "./css/untitled.css"



import React from "react"


import {
    BrowserRouter as Router,
    Redirect,
    Switch,
    Route,
    Link
  } from "react-router-dom";


//SIEMPRE PONER EL "/" EL ULTIMo
class App extends React.Component{
    constructor() {
        super()
    }

    render(){
    return (
      <Router>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
          {/* <Route path="/confirmexchange" component={ConfirmExchangePageCompact}/>
          <Route path="/complete-exchange" component={CompleteExchangeCompact}/> */}
          <Route exact path='/aboutme' component={AboutMe}/>
          <Route exact path='/login' component={Login}/>
          <Route exact path='/contact' component={Contact}/>
          <Route exact path='/dashboard' component={Dashboard}/>
          <Route exact path='/view-experiment/:name' component={SingleProduct}/>
          <Route exact path='/experiments' component={AllProjects}/>





          <Route exact path='/' component={HomePage}/>
          
          {/* <Redirect to='/' /> */}
            

              
          </Switch>
      </Router>
    )
}
  }


export default App