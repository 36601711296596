import React from "react"
import {Link} from "react-router-dom"
import axios from "axios"

import Innerbox from "./innerbox"
import Header from "../header"
import Footer from "../footer"

//confirmexchange
class HomePage extends React.Component {
    constructor(){
        
        super()
        this.state = {
            redirect: false,
            termsOfUseCheckbox: true,
        }
        this.handleChange = this.handleChange.bind(this)

    }

    componentDidMount() {
        var self = this
        axios.get(process.env.REACT_APP_API_DOMAIN + '/print_projects')
        .then((resp) => {
            
            var sortedList = resp.data.sort(function(a,b){
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              console.log(new Date(b.date) - new Date(a.date))
              return new Date(b.date) - new Date(a.date);
            });
            
            console.log(sortedList)
            self.setState({projects:sortedList})

          })
        .catch(error => {
          console.log(error)
          })
    }



    handleChange(event){
        const {name,value,type,checked} = event.target
        type === "checkbox" ? this.setState({[name]:checked}) : this.setState({[name]: value})
    }


    render(){


        return(
          <div>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />

          <Header/>

          <div style={{marginTop: 72}}>
            <div className="container customIndex" style={{paddingLeft: '10%', paddingRight: '10%'}}>
              <div className="row customIndex">
                <div className="col-md-6 customIndex">
                  <h1 className="bigHeader bigger" >Welcome to my portfolio of past projects!</h1>
                  <p style={{fontSize: 22}}>Here lies the remains of some of my projects. Maybe you'll learn. Mostly you'll laugh. Definitely you'll enjoy.<br /></p>
                  <div className="row" style={{marginTop: 27}}>
                    <div className="col">
                      <Link to="/aboutme" ><p className="DzButton" style={{paddingRight: 10, paddingBottom: 5, paddingLeft: 10}}>About Me</p></Link>
                    </div>
                    <div className="col">
                    <Link to="/experiments" ><p className="DzButton2">Experiments</p></Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex d-sm-flex d-xl-flex justify-content-center justify-content-sm-center align-items-sm-center justify-content-xl-center"><img src="assets/img/dzcientificBIEN.svg" style={{width: 226}} /></div>
              </div>
            </div>
          </div>
          <section className="d-xl-flex flex-column justify-content-xl-center align-items-xl-center" style={{marginTop: 98}}>
            <h1 className="text-center bigHeader" style={{fontSize: 50}}>Recent Experiments</h1>
            <div className="container d-xl-flex justify-content-xl-center align-items-xl-start flexcontainer" style={{marginTop: 71}}>


              {(this.state.projects) ? this.state.projects.slice(0, 3).map((project) => (
                
                  <Innerbox id={project.name} date={project.date} description={project.shortdesc} img={project.image}/>
              )) : null}
            </div>
          </section>

          <Footer/>
        </div>
        
        )
    
}


} 

export default HomePage