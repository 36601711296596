import React from "react";
import { Link } from "react-router-dom";
import axios from "axios"

import Innerbox from "../homePage/innerbox"
import Header from "../header";
import Footer from "../footer"
//confirmexchange
class AllProjects extends React.Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      termsOfUseCheckbox: true,
      search: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var self = this
    axios.get(process.env.REACT_APP_API_DOMAIN + '/print_projects')
    .then((resp) => {
        
        var sortedList = resp.data.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.date) - new Date(a.date);
        });
        
        self.setState({projects:sortedList})

      })
    .catch(error => {
      console.log(error)
      })
}


  handleChange(event) {
    const { name, value, type, checked } = event.target;
    type === "checkbox"
      ? this.setState({ [name]: checked })
      : this.setState({ [name]: value });
  }

  render() {

    if (this.state.projects){

    var filteredProjects = 
    this.state.projects.filter(project => {
      return project.name.toLowerCase().includes(this.state.search.toLowerCase())
    })

    //sorteamos por los nombres mas peq prim
    filteredProjects = filteredProjects.sort(function(a,b){
      return a.name.length - b.name.length
    })
  }

    return (
      <div>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
        />
        <title>All My Experiments</title>
        <link rel="stylesheet" href="assets/bootstrap/css/bootstrap.min.css" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700"
        />

        <Header />

        <div>
          <section
            className="d-flex d-lg-flex d-xl-flex flex-column align-items-lg-center justify-content-xl-center align-items-xl-center"
            style={{ marginTop: 98 }}
          >
            <h1 className="text-center bigHeader" style={{ fontSize: 50 }}>
              All Experiments
            </h1>
            <input
              type="text"
              id="search"
              className="searchbar"
              style={{
                marginTop: 18,
                width: "60%",
                height: 40,
                border: "solid 2px #a4b1c7",
                borderRadius: "1rem !important",
                backgroundColor: "#141d2b",
                textAlign: "center",
                /*marginLeft: 'auto', */ fontSize: 20,
                outlineWidth: "0px !important",
                color: "#ffffff",
              }}
              onChange={this.handleChange}
              value={this.state.search}
              placeholder="Search"
              name="search"
            />
            <div
              className="container d-xl-flex justify-content-xl-center align-items-xl-start flexcontainer"
              style={{ marginTop: 71 }} 
            >


            {(this.state.search && filteredProjects && this.state.search.length > 0) ? filteredProjects.map((project) => (
                <Innerbox id={project.name} date={project.date} description={project.shortdesc} img={project.image}/>)) :
              (this.state.projects) ? this.state.projects.map((project) => (<Innerbox id={project.name} date={project.date} description={project.shortdesc} img={project.image}/>)) :
              null
            }

            </div>
            {/* <footer>
              <div className="d-sm-flex d-xl-flex flex-row justify-content-sm-center align-items-sm-center justify-content-xl-center align-items-xl-center">
                <p
                  className="boxHeading"
                  style={{ fontSize: "18px !important" }}
                >
                  1-9 of 20 Posts
                  <i
                    className="fa fa-arrow-right"
                    style={{ marginLeft: 8, fontSize: 22, color: "#a4b1c7" }}
                  />
                </p>
              </div>
            </footer> */}
          </section>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default AllProjects;
