import React from "react";

function Footer() {
  return (
    <div style={{marginTop:"60px", marginBottom:"10px", display:"flex", alignContent:"center", justifyContent:"center"}}>
    <p style={{textAlign:"center"}}>Fully stacked by <b>ErikDz</b></p>
    </div>
  );
}

export default Footer;