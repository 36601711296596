import React from "react"
import {Link} from "react-router-dom"

import Header from "../header"
import Footer from "../footer"

//confirmexchange
class AboutMe extends React.Component {
    constructor(){
        
        super()
        this.state = {
            redirect: false,
            termsOfUseCheckbox: true,
        }
        this.handleChange = this.handleChange.bind(this)

    }

    componentDidMount() {

        console.log("lets do this")
    }

    handleChange(event){
        const {name,value,type,checked} = event.target
        type === "checkbox" ? this.setState({[name]:checked}) : this.setState({[name]: value})
    }


    render(){


        return(
          <div>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
          <title>About Me</title>

          <Header/>

          <div>

        <div className="container d-xl-flex flex-column align-items-xl-center">
            <h1 className="bigHeader">About Me</h1><img className="dzface" src="assets/img/erikdz.jpg" style={{border: '0px solid #dee2e6!important'}} /></div>
        <div className="container" style={{marginTop: 25}}>
            <blockquote className="blockquote" style={{borderLeft: '3px solid #ccc', paddingLeft: 10}}>
            <p className="mb-0">“The difference between screwing around and science is writing it down.”</p>
            <footer className="blockquote-footer"><strong>Adam Savage</strong></footer>
            </blockquote>
            <p style={{marginTop: 62}}>Hi! My name is Erik Dziekonski. I've been doing a plethora of projects and courses throughout my life so that's why I coded this site; a simple way of keeping track of all of them.<br /><br />Right now, I'll be studying on the École Polytechnique
            of Paris. I'm hoping to get a double degree in Mathematics and Computer Science by 2024 and enroll on a masters degree on artificial intelligence.<br /><br /><br /></p>
            <div className="d-xl-flex flex-column justify-content-xl-center">
            <h1 className="boxHeading">Known languages</h1>
            <div>
                <img style={{height:"50px", padding:"0 5px 0 5px"}} src="https://countryflagsapi.com/png/es"/>
                <img style={{height:"50px", padding:"0 5px 0 5px"}} src="https://countryflagsapi.com/png/gb"/>
                <img style={{height:"50px", padding:"0 5px 0 5px"}} src="https://countryflagsapi.com/png/fr"/>
            </div>
            </div>
            </div>
            </div>
            <Footer/>
        </div>

        
        )
    
}


} 

export default AboutMe