import React from "react"
import {Link} from "react-router-dom"

import Header from "../header"
import Footer from "../footer"

import "../../bootstrap/css/bootstrap.min.css"

import { FaHourglassEnd } from 'react-icons/fa';
import {BiMailSend, BiPhoneCall} from "react-icons/bi";

class Contact extends React.Component {
    constructor(){
        
        super()
        this.state = {
            redirect: false,
            termsOfUseCheckbox: true,
        }
        this.handleChange = this.handleChange.bind(this)

    }

    componentDidMount() {

        console.log("lets do this")
    }

    handleChange(event){
        const {name,value,type,checked} = event.target
        type === "checkbox" ? this.setState({[name]:checked}) : this.setState({[name]: value})
    }


    render(){


        return(
          <div>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
          <title>Contact</title>
          <Header/>

            <div style={{marginTop:"20px", marginBottom:"20%"}}>

    <section className="d-xl-flex justify-content-xl-center">
        <h1 className="bigHeader" style={{position: 'absolute', /*left: 0, *//*left: '43%', *//*right: 0, */marginLeft: 10}}>Contact Me</h1>
    </section>
    <div className="container d-xl-flex flex-column align-items-xl-start">
        <div className="flex-grow-1 flex-fill" />
        <div style={{marginTop: 66}}>
        <p className="boxHeading">
            <FaHourglassEnd style={{color: 'rgb(255,255,255)', fontSize: 36}}/>
            &nbsp; &nbsp;<strong>When am I available:</strong>&nbsp;</p>
        <p>GMT+2: 8:00-20:30</p>
        </div>
    </div>
    <div className="container" style={{marginTop: 25}}>
        <p className="boxHeading"><BiMailSend style={{color: 'rgb(255,255,255)', fontSize: "40px"}}/><i className="icon ion-android-mail" style={{color: 'rgb(255,255,255)', fontSize: 36}} />&nbsp; Email:</p>
        <p>contact@erikdziekonski.com</p>
    </div>
    <div className="container" style={{marginTop: 25}}>
        <p className="boxHeading"><BiPhoneCall style={{color: 'rgb(255,255,255)', fontSize: "40px"}}/><i className="fa fa-phone" style={{color: 'rgb(255,255,255)', fontSize: 36}} />&nbsp; Phone number:</p>
        <p>+34 622 571 575</p>
    </div>
    </div>
    <Footer/>
    </div>


        
        )
    
}


} 

export default Contact